<template>
  <div v-if="platformPermissionsLoaded && checkPermission('learn.insights.ldinsights')">
    <CRow>
      <CCol cols="12" xl="12" lg="12" md="12" sm="12" class="pb-0">
        <div class="d-flex">
          <h1 class="dashboard_page_title flex-grow-1">{{$t('insights.Learn_insights')}}</h1>
          <div v-if="showDownloadButtons" class="insights_download_buttons text-right">
            <div class="mt-1 mt-xl-0 d-inline-block align-top">
              <CButton class="link m-0" color="primary" @click="makeScreenshot()">
                <i class="fas fa-image mr-1"/><span>{{ $t('insights.Save_as_image') }}</span>
              </CButton>                
            </div>
          </div>
        </div>
      </CCol>
    </CRow>

    <CRow class="insights_filters">
      <CCol cols="2" xl="2" lg="3" md="3" sm="12">
        <b-datepicker v-model="dateFrom"
                      icon="calendar-day"
                      icon-pack="fas"
                      :first-day-of-week="1"
                      :show-week-number="true"
                      :max-date="new Date()"
                      :years-range="[-3, 10]"
                      :placeholder="$t('common.click_to_select')"
                      @input="getInsightsData(); getLeaderboardData();"
                      class="d-inline-block align-middle">
          <CButton color="primary" @click="dateFrom = new Date(); getInsightsData(); getLeaderboardData();">
            <i class="fas fa-calendar-day mr-1"/>{{$t('Today')}}
          </CButton>
        </b-datepicker>
      </CCol>
      <CCol cols="2" xl="2" lg="3" md="3" sm="12">
        <b-datepicker v-model="dateTo"
                      icon="calendar-day"
                      icon-pack="fas"
                      :first-day-of-week="1"
                      :show-week-number="true"
                      :max-date="new Date()"
                      :years-range="[-3, 10]"
                      :placeholder="$t('common.click_to_select')"
                      @input="getInsightsData(); getLeaderboardData();"
                      class="d-inline-block align-middle">
          <CButton color="primary" @click="dateTo = new Date(); getInsightsData(); getLeaderboardData();">
            <i class="fas fa-calendar-day mr-1"/>{{$t('Today')}}
          </CButton>
        </b-datepicker>
      </CCol>
      <CCol cols="3" xl="3" lg="3" md="3" sm="12">        
        <multiselect
          class="data_table open_absolute multiple"
          v-model="selectedCourses"
          :options="courses" 
          :multiple="true"
          :hide-selected="true"
          :placeholder="$t('common.Filter_on_course')" 
          :selectLabel="$t('common.Select_course')"
          track-by="lms_course_id_external" 
          label="label"
          @input="getInsightsData(); getLeaderboardData();">
          <span slot="noResult">{{$t('common.no_categories_found')}}</span>
        </multiselect>
      </CCol>
      <CCol cols="3" xl="3" lg="3" md="3" sm="12">
        <multiselect
          class="data_table open_absolute multiple"
          v-model="selectedTeams"
          :options="departments" 
          :multiple="true"
          :hide-selected="true"
          :group-select="true"
          group-values="teams" 
          group-label="department_name"
          :placeholder="$t('common.Filter_on_team')"
          :selectLabel="$t('common.Add_team')"
          track-by="team_id" 
          label="team_name"
          @input="getInsightsData(); getLeaderboardData();">
          <span slot="noResult">{{ $t('common.no_teams_found') }}</span>
        </multiselect> 
      </CCol>
    </CRow>

    <CRow ref="screenshotContent" class="insights" v-bind:class="{'printing' : printingInsights}">
      <CCol cols="4" xl="4" lg="4" md="4" sm="4">
        <CCard class="h-100 mb-0">
          <CCardHeader>
            <CRow>
              <CCol cols="12" xl="12" lg="12" md="12" sm="12" class="text-left pt-0 pb-0">
                {{$t('insights.Courses_completed')}}
              </CCol>       
            </CRow>       
          </CCardHeader>
          <CCardBody class="pt-0">
            <div v-if="loadingInsightsData" class="h-100 d-flex align-items-center">
              <loadingSpinner mode="auto" :content="null"/>
            </div>
            <Count v-else
                   mode="with_description"
                   :count="insightsData.completed[0].courses_completed"
                   :countTitle="$t('By') + ' ' + insightsData.completed[0].courses_completed_amount_employees + ' ' + $t('insights.employees')"
                   :countIcon="null"/>
          </CCardBody>
        </CCard>
      </CCol>

      <CCol cols="4" xl="4" lg="4" md="4" sm="4">
        <CCard class="h-100 mb-0">
          <CCardHeader>
            <CRow>
              <CCol cols="12" xl="12" lg="12" md="12" sm="12" class="text-left pt-0 pb-0">
                {{$t('insights.Courses_in_progress')}}
              </CCol>       
            </CRow>       
          </CCardHeader>
          <CCardBody class="pt-0">
            <div v-if="loadingInsightsData" class="h-100 d-flex align-items-center">
              <loadingSpinner mode="auto" :content="null"/>
            </div>
            <Count v-else
                   mode="with_description"
                   :count="insightsData.in_progress[0].courses_in_progress"
                   :countTitle="$t('By') + ' ' + insightsData.in_progress[0].courses_in_progress_amount_employees + ' ' + $t('insights.employees')"
                   :countIcon="null"/>
          </CCardBody>
        </CCard>        
      </CCol>

      <CCol cols="4" xl="4" lg="4" md="4" sm="4">
        <CCard class="h-100 mb-0">
          <CCardHeader>
            <CRow>
              <CCol cols="12" xl="12" lg="12" md="12" sm="12" class="text-left pt-0 pb-0">
                {{$t('insights.Average_courses_per_employee')}}
              </CCol>       
            </CRow>       
          </CCardHeader>
          <CCardBody class="pt-0">
            <div v-if="loadingInsightsData" class="h-100 d-flex align-items-center">
              <loadingSpinner mode="auto" :content="null"/>
            </div>
              <Count v-else
                     mode="single_number"
                     :count="(insightsData.completed[0].courses_completed / insightsData.headcount.headcount_total).toFixed(2)"
                     :countTitle="null"
                     :countIcon="null"/>
          </CCardBody>
        </CCard>        
      </CCol>

      <CCol cols="12" xl="12" lg="12" md="12" sm="12">
        <CCard class="h-100 mb-0">
          <CCardHeader class="pb-0">
            <CRow>
              <CCol cols="12" xl="12" lg="12" md="12" sm="12" class="text-left pt-0 pb-0">
                {{$t('insights.Training_trend')}}
              </CCol>       
            </CRow>       
          </CCardHeader>
          <CCardBody class="pt-0" v-bind:class="{'p-0' : !loadingInsightsData}">
            <div v-if="loadingInsightsData" class="h-100 d-flex align-items-center">
              <loadingSpinner mode="auto" :content="null"/>
            </div>
            <LineChart v-else :lineChartSeries="lineChartSeries" xAxisType="datetime" yAxisType="int" :companyPrimaryColor="companyPrimaryColor"/>
          </CCardBody>
        </CCard>        
      </CCol> 

      <CCol cols="8" xl="8" lg="8" md="8" sm="8">
        <CCard class="h-100 mb-0">
          <CCardHeader class="pb-0">
            <CRow>
              <CCol cols="12" xl="12" lg="12" md="12" sm="12" class="text-left pt-0">
                {{$t('insights.Courses')}}
              </CCol>       
            </CRow>       
          </CCardHeader>
          <CCardBody class="pt-0" v-bind:class="{'p-0' : !loadingInsightsData}">
            <div v-if="loadingInsightsData" class="h-100 d-flex align-items-center">
              <loadingSpinner mode="auto" :content="null"/>
            </div>
            <CourseTable v-else :courses="insightsData.courses"/>
          </CCardBody>
        </CCard>        
      </CCol>

      <CCol cols="4" xl="4" lg="4" md="4" sm="4">
        <CCard class="h-100 mb-0">
          <CCardHeader>
            <CRow>
              <CCol cols="12" xl="12" lg="12" md="12" sm="12" class="text-left pt-0 pb-0">
                {{$t('insights.Top_completed_courses')}}
              </CCol>       
            </CRow>       
          </CCardHeader>
          <CCardBody class="pt-0">
            <div v-if="loadingInsightsData" class="h-100 d-flex align-items-center">
              <loadingSpinner mode="auto" :content="null"/>
            </div>
            <HighlightStage v-else
                            :stageData="insightsData.courses"
                            :scoreValue="'highlight_score'"
                            :nameValue="'course_name'"
                            :idValue="'lms_course_id_external'"
                            :showDecimals="false"
                            :navigateTo="null">
            </HighlightStage>
          </CCardBody>
        </CCard>        
      </CCol>

      <CCol cols="8" xl="8" lg="8" md="8" sm="8">
        <CCard class="h-100 mb-0">
          <CCardHeader class="pb-0">
            <CRow>
              <CCol cols="12" xl="12" lg="12" md="12" sm="12" class="text-left pt-0">
                {{$t('insights.Leaderboard_attendees')}}
              </CCol>       
            </CRow>       
          </CCardHeader>
          <CCardBody class="pt-0" v-bind:class="{'p-0' : !loadingInsightsData}">
            <div v-if="loadingInsightsData" class="h-100 d-flex align-items-center">
              <loadingSpinner mode="auto" :content="null"/>
            </div>
            <LeaderboardTable v-else :leaderboard="leaderboardData"/>
          </CCardBody>
        </CCard>        
      </CCol>      

      <CCol cols="4" xl="4" lg="4" md="4" sm="4">
        <CCard class="h-100 mb-0">
          <CCardHeader>
            <CRow>
              <CCol cols="12" xl="12" lg="12" md="12" sm="12" class="text-left pt-0 pb-0">
                {{$t('insights.Top_attendees')}}
              </CCol>       
            </CRow>       
          </CCardHeader>
          <CCardBody class="pt-0">
            <div v-if="loadingInsightsData" class="h-100 d-flex align-items-center">
              <loadingSpinner mode="auto" :content="null"/>
            </div>
            <HighlightStage v-else
                            :stageData="leaderboardData"
                            :scoreValue="'courses_completed'"
                            :nameValue="'user_name'"
                            :idValue="'user_id_external'"
                            :showDecimals="false"
                            :navigateTo="null">
            </HighlightStage>
          </CCardBody>
        </CCard>        
      </CCol>                
    </CRow> 
  </div>
  <noPermission v-else-if="platformPermissionsLoaded" trigger="permission"/>
</template>

<script>
import axios from 'axios'
import Multiselect from 'vue-multiselect';

import loadingSpinner from '@/components/common/loadingSpinner.vue';
import noPermission from '@/components/common/noPermission.vue';

import Count from '@/components/insights/common/Count.vue';
import LineChart from '@/components/insights/common/LineChart.vue';
import HighlightStage from '@/components/insights/common/HighlightStage.vue';

import CourseTable from '@/components/insights/learn/CourseTable.vue';
import LeaderboardTable from '@/components/insights/learn/LeaderboardTable.vue';

export default {
  name: 'Item',
  components: {
    Multiselect,
    loadingSpinner,
    noPermission,
    Count,
    LineChart,
    HighlightStage,
    CourseTable,
    LeaderboardTable
  },
  data() {
    return {
      platformPermissions: [],
      platformPermissionsLoaded: false,      
      loadingInsightsData: false,
      loadingLeaderboardData: false,
      insightsData: {
        completed: [{}],
        in_progress: [{}],
        headcount: {
          headcount_total: 0
        }        
      },
      leaderboardData: {},
      showDownloadButtons: false,
      printingInsights: false,      
      selectedTeams: [],
      departments: [],
      selectedCourses: [],
      courses: [],
      dateFrom: new Date(),
      dateTo: new Date(),
      companyPrimaryColor: null,
      lineChartSeries: [],
    }
  },
  methods: {
    getInsightsData() {
      let params = {};
      params.teams = [];
      params.courses = [];
            
      for(let t = 0; t < this.selectedTeams.length; t++) {
        params.teams.push(this.selectedTeams[t].team_id);
      }

      for(let c = 0; c < this.selectedCourses.length; c++) {
        params.courses.push(this.selectedCourses[c].lms_course_id_external);
      }

      // Start the loader
      this.loadingInsightsData = true;
      // Format the dates for the API       
      let dateFromAPI = this.$luxon(this.dateFrom.toISOString(), "yyyy-MM-dd");
      let dateToAPI = this.$luxon(this.dateTo.toISOString(), "yyyy-MM-dd");
      // Get the topics insights data
      axios.post(process.env.VUE_APP_API_URL + '/v1/learn/insights/courses/' + dateFromAPI + '/' + dateToAPI, params)      
      .then(res => {
        // Set the insightsData
        this.insightsData = res.data.data;
        // Set the companyPrimaryColor value
        this.companyPrimaryColor = getComputedStyle(document.documentElement).getPropertyValue('--DashboardPrimary');        
        // Update the lineChartSeries
        this.lineChartSeries = [{ name: this.$t('insights.Trend_courses_completed'), data: this.insightsData.chart.completed }];
        // Set timeout for 2 seconds
        setTimeout(function(){
          // Close the loader
          this.loadingInsightsData = false;
          // Update the showDownloadButtons value
          this.showDownloadButtons = true;            
        }.bind(this), 2000)                         
      })
      .catch(err => {
        console.error(err); 
      });                  
    },
    getLeaderboardData() {
      let params = {};
      params.teams = [];
      params.courses = [];
            
      for(let t = 0; t < this.selectedTeams.length; t++) {
        params.teams.push(this.selectedTeams[t].team_id);
      }

      for(let c = 0; c < this.selectedCourses.length; c++) {
        params.courses.push(this.selectedCourses[c].lms_course_id_external);
      }

      // Start the loader
      this.loadingLeaderboardData = true;
      // Format the dates for the API       
      let dateFromAPI = this.$luxon(this.dateFrom.toISOString(), "yyyy-MM-dd");
      let dateToAPI = this.$luxon(this.dateTo.toISOString(), "yyyy-MM-dd");
      // Get the topics insights data
      axios.post(process.env.VUE_APP_API_URL + '/v1/learn/insights/leaderboard/' + dateFromAPI + '/' + dateToAPI, params)      
      .then(res => {
        // Set the leaderboardData
        this.leaderboardData = res.data.data;
        // Set timeout for 2 seconds
        setTimeout(function(){
          // Close the loader
          this.loadingLeaderboardData = false;
          // Update the showDownloadButtons value
          this.showDownloadButtons = true;            
        }.bind(this), 2000)                         
      })
      .catch(err => {
        console.error(err); 
      });
    },
    getCourses() {
      axios.get(process.env.VUE_APP_API_URL + '/v1/learn/courses/multiselect')
      .then(res => {
        this.courses = res.data.data;
      })
      .catch(err => {
        console.error(err); 
      });
    },
    getDepartments() {
      axios.get(process.env.VUE_APP_API_URL + '/v1/core/organisation/departmentTeams')
      .then(res => {
        // Get departments and teams from API response        
        var departments = res.data.data.departments;
        var teams = res.data.data.teams;
        // Empty departments array
        this.departments = [];        
        // Loop through departments
        for(var d = 0; d < departments.length; d++) {
          // Push new department into departments array
          this.departments.push({
            department_id: departments[d].department_id,
            department_name: departments[d].department_name,
            teams: []
          });

          // Loop through teams
          for(var t = 0; t < teams.length; t++) {
            // If a team is part of a department, push the team into the teams array of this department
            if(departments[d].department_id === teams[t].department_id) {
              var index = this.departments.findIndex(i => i.department_id == departments[d].department_id);
              this.departments[index].teams.push({
                team_id: teams[t].team_id,
                team_name: teams[t].team_name  + ' (' + departments[d].department_name + ')'
              });
            }
          }
        }
      })
      .catch(err => {
        console.error(err); 
      });
    },
    makeScreenshot() {
      window.scroll(0,0);
      // Update printingInsights value
      this.printingInsights = true;
      // Get the HTML to print
      const content = this.$refs.screenshotContent;
      // Set the html2canvas options
      const options = { type: "dataURL", useCORS: true };

      setTimeout(function() {
        // Print the screenshot
        this.printScreenshot(content, options);
        // Reset the printingInsights value
        this.printingInsights = false;
      }.bind(this), 500);
    },
    async printScreenshot(content, options) {
      // Create canvas for printing
      const printCanvas = await html2canvas(content, options);
      // Creata a link element to download the generated canvas
      const link = document.createElement("a");
    
      link.setAttribute("download", "knowledge_insights_" + new Date().toLocaleString() + ".png");
      link.setAttribute("href", printCanvas.toDataURL("image/png").replace("image/png", "image/octet-stream"));
      link.click();
    },
    exportData(outputData, outputFormat) {
      this.$buefy.toast.open({ message: this.$t('common.Export_successful'), type: 'is-success', duration: 2000 });
           
      let exportFileName = 'knowledge-insights';      

      if (!window.navigator.msSaveOrOpenBlob){
        // Blob navigator
        let url = null;
        // Set the output data and URL based on the output format
        if(outputFormat === 'csv') {
          outputData = outputData.data;
          url = window.URL.createObjectURL(new Blob([outputData]));  
        } else if(outputFormat === 'json') {
          outputData = JSON.stringify(outputData.data.data);
          url = window.URL.createObjectURL(new Blob([outputData]));
        }
        // Create a new 'a' element
        const link = document.createElement('a');
        // Set the generated URL as href of the new element
        link.href = url;
        // Set the download attribute based on the output format
        if(outputFormat === 'csv') {          
          link.setAttribute('download', exportFileName + '.csv');
        } else if(outputFormat === 'json') {
          link.setAttribute('download', exportFileName + '.json');
        }
        // Append the link to the body
        document.body.appendChild(link);
        // Click the link
        link.click();
      } else {
        // Blob for Explorer 11
        let url = null;
        // Set the output data and URL based on the output format
        if(outputFormat === 'csv') {
          outputData = outputData.data;
          url = window.navigator.msSaveOrOpenBlob(new Blob([outputData]), exportFileName + '.csv');
        } else if(outputFormat === 'json') {
          outputData = JSON.stringify(outputData.data.data);
          url = window.navigator.msSaveOrOpenBlob(new Blob([outputData]), exportFileName + '.json');
        }
      }
    },
    getPlatformPermissions() {
      axios.get(process.env.VUE_APP_API_URL + '/v1/core/platform/permissions')
      .then(res => {      
        this.platformPermissions = res.data.data;
        // Update the platformPermissionsLoaded value
        this.platformPermissionsLoaded = true;
      })
      .catch(err => {
        console.error(err); 
      });
    },
    checkPermission(permissionTag) {
      if(this.platformPermissions.includes(permissionTag)) {
        return true;
      } else{
        return false;
      }
    }     
  },
  mounted: function() {
    // Set the default for dateFrom to today minus 90 days
    this.dateFrom.setDate(this.dateFrom.getDate() -90);

    this.getPlatformPermissions();

    // Get the insights data
    this.getInsightsData();
    this.getLeaderboardData();
    // Get the courses
    this.getCourses();
    // Get the departments
    this.getDepartments();
  }
}
</script>